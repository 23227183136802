import React from 'react';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * Why Us page Hero component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Hero />
 */

const Hero = () => (
  <section
    className={cx(
      base.flex,
      base.flexColumn,
      base.itemsCenter,
      base.justifyCenter,
      base.w100,
      main.heroWhy
    )}
  >
    <h1 className={cx(base.tc, main.heading, theme.colorDark)}>
      Member engagement, <span className={theme.colorBlue}>reimagined</span>.
    </h1>
    <p
      className={cx(base.ma0, base.tc, base.w100, main.stCopy, theme.colorDark)}
    >
      We believe that member engagement should be built on a foundation of
      actionable data insights based on machine learning, artificial
      intelligence, and depth of experience. Too often, data is compiled and
      analyzed with no clear path as to how it can be used most effectively. For
      us, data is a starting point to something much larger: strategic member
      engagement that’s targeted to the right members and personalized to each
      unique lifestyle and preferences.
    </p>
  </section>
);

export default Hero;
