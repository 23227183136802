import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';

import CleanHTML from '../cleanHTML';

const copyImg = {
  maxHeight: '460px',
  maxWidth: '460px',
  minHeight: '316px',
  minWidth: '316px',
};

/**
 * Why Us page Statements component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Statements />
 */

const Statements = () => {
  const { buzz, easy, members } = useStaticQuery(graphql`
    query {
      buzz: contentfulAsset(title: { eq: "why-buzz" }) {
        fluid(maxWidth: 550, quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      easy: contentfulAsset(title: { eq: "why-easy" }) {
        fluid(maxWidth: 550, quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      members: contentfulAsset(title: { eq: "why-members" }) {
        fluid(maxWidth: 550, quality: 80, resizingBehavior: FILL) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  `);

  const statements = [
    {
      alt: 'Pie chart with health related items.',
      copy:
        'We understand that a member’s medical care tells only a small part of their healthcare story. To make informed decisions and impact member behavior, our platform builds deep and layered member profiles, including <strong>social determinants of health</strong>.',
      flipped: false,
      heading: 'Members are multifaceted.',
      image: members.fluid,
    },
    {
      alt: 'A human brain next to a caduceus.',
      copy:
        '<strong>Artificial intelligence, machine learning, predictive analytics...</strong> We see and hear them every day. But what do they really mean and what aspects can be valuable? Without extensive healthcare experience behind them, they’re a means to an end. Our expertise in applying these tools in real-world plan settings make them integral to boosting performance. Not just buzzwords.',
      flipped: true,
      heading: 'Buzzwords brought to life.',
      image: buzz.fluid,
    },
    {
      alt: 'Cloud storage icon connected to various health related icons.',
      copy:
        '<strong>Data integration can be a daunting task.</strong> We make it easy. We accept data in any format, allowing us to do the heavy lifting so you can spend your time on the things that matter most. Oh, and did we mention we have our clients up and running within 45 days?',
      flipped: false,
      heading: 'Complicated, made easy.',
      image: easy.fluid,
    },
  ];

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.data
      )}
    >
      {statements.map((items, i) => (
        <div
          key={items.image.src}
          className={cx(
            base.flex,
            {
              [base.flexWrap]: items.flipped === false,
              [base.flexWrapReverse]: items.flipped,
            },
            base.itemsCenter,
            base.justifyBetween,
            base.w100,
            main.dataItemLg,
            {
              [main.mb60]: i !== 2,
              [main.mb90]: i === 2,
            }
          )}
        >
          <Img
            fluid={items.image}
            alt={items.alt}
            style={copyImg}
            imgStyle={copyImg}
            className={cx({ [base.order2]: items.flipped })}
          />
          <div
            className={cx(
              base.ma2,
              base.w100,
              {
                [base.order1]: items.flipped,
              },
              main.imgCopyWrap
            )}
          >
            <p className={cx(base.mb2, base.mt0, main.subHeading)}>
              {items.heading}
            </p>
            <p className={cx(base.ma0, main.stCopy)}>
              <CleanHTML html={items.copy} />
            </p>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Statements;
