import React from 'react';
import PropTypes from 'prop-types';

import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

import Build from '../components/why-us/whyBuild';
import CTA from '../components/ctaContainer';
import Get from '../components/why-us/whyGet';
import Hero from '../components/why-us/whyHero';
import Layout from '../components/layout';
import Statements from '../components/why-us/whyStatements';

const pageDescription =
  'Modern member engagement built on a foundation of actionable data insights based on machine learning, artificial intelligence, and clinical experience.';

/**
 * Why Us page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <Why location={string} />
 */

const Why = ({ location }) => (
  <Layout
    pageTitle="Why Us"
    pageDescription={pageDescription}
    location={location}
  >
    <Hero />
    <Statements />
    <Build />
    <Get />
    <CTA
      background={main.colorGradientBlue}
      button={theme.dpBtnBlue}
      color={theme.colorBlue}
      solution="profile"
    />
  </Layout>
);

Why.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Why;
