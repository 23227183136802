import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * Why Us page Get component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Get />
 */

const Get = () => {
  const {
    canvas,
    document,
    globe,
    microscope,
    privacy,
    stock,
  } = useStaticQuery(graphql`
    query {
      canvas: contentfulAsset(title: { eq: "why-canvas" }) {
        file {
          url
        }
      }
      document: contentfulAsset(title: { eq: "why-document" }) {
        file {
          url
        }
      }
      globe: contentfulAsset(title: { eq: "why-globe" }) {
        file {
          url
        }
      }
      microscope: contentfulAsset(title: { eq: "why-microscope" }) {
        file {
          url
        }
      }
      privacy: contentfulAsset(title: { eq: "why-privacy" }) {
        file {
          url
        }
      }
      stock: contentfulAsset(title: { eq: "why-stock" }) {
        file {
          url
        }
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100,
        main.mb120,
        main.whyGetWrapper
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsStart,
          base.justifyCenter,
          base.w100,
          main.dataItem
        )}
      >
        <ul
          className={cx(
            base.flex,
            base.flexWrap,
            base.itemsStart,
            base.justifyBetween,
            base.ma0,
            base.pa0,
            base.w100
          )}
        >
          <li
            className={cx(
              base.flex,
              base.itemsCenter,
              base.justifyStart,
              base.w100,
              main.whyGetItem
            )}
          >
            <img
              src={stock.file.url}
              alt="Stocks line chart."
              className={main.iconImg}
            />
            <p className={cx(base.ma0, theme.title3)}>
              Current and future state
            </p>
          </li>
          <li
            className={cx(
              base.flex,
              base.itemsCenter,
              base.justifyStart,
              base.w100,
              main.whyGetItem
            )}
          >
            <img
              src={privacy.file.url}
              alt="Two poeple shaking hands."
              className={main.iconImg}
            />
            <p className={cx(base.ma0, theme.title3)}>
              Behavioral/motivational interview
            </p>
          </li>
          <li
            className={cx(
              base.flex,
              base.itemsCenter,
              base.justifyStart,
              base.w100,
              main.whyGetItem
            )}
          >
            <img
              src={microscope.file.url}
              alt="Microscope."
              className={main.iconImg}
            />
            <p className={cx(base.ma0, theme.title3)}>Evaluation criteria</p>
          </li>
          <li
            className={cx(
              base.flex,
              base.itemsCenter,
              base.justifyStart,
              base.w100,
              main.whyGetItem
            )}
          >
            <img src={canvas.file.url} alt="Canvas." className={main.iconImg} />
            <p className={cx(base.ma0, theme.title3)}>
              Practice transformation
            </p>
          </li>
          <li
            className={cx(
              base.flex,
              base.itemsCenter,
              base.justifyStart,
              base.w100,
              main.whyGetItem
            )}
          >
            <img src={globe.file.url} alt="Globe." className={main.iconImg} />
            <p className={cx(base.ma0, theme.title3)}>
              Resource planning assessment
            </p>
          </li>
          <li
            className={cx(
              base.flex,
              base.itemsCenter,
              base.justifyStart,
              base.w100,
              main.whyGetItem
            )}
          >
            <img
              src={document.file.url}
              alt="News article."
              className={main.iconImg}
            />
            <p className={cx(base.ma0, theme.title3)}>Compliance reporting</p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Get;
