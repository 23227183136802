import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import cx from 'classnames';

import base from '../../styles/base.module.css';
import main from '../../styles/main.module.css';
import theme from '../../styles/theme.module.css';

/**
 * Why Us page Build component. Data sourced from Contentful.
 * @component
 *
 * @returns {component} - <Build />
 */

const Build = () => {
  const { contentfulAsset } = useStaticQuery(graphql`
    query {
      contentfulAsset(title: { eq: "why-build" }) {
        file {
          url
        }
      }
    }
  `);

  return (
    <section
      className={cx(
        base.flex,
        base.flexColumn,
        base.itemsCenter,
        base.justifyCenter,
        base.w100
      )}
    >
      <div
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyCenter,
          base.w100,
          main.dataItem
        )}
      >
        <img
          src={contentfulAsset.file.url}
          alt="Castle tower with three start underneath."
          className={main.whyBuildImg}
        />
        <p
          className={cx(
            base.tc,
            main.subHeading,
            main.whyBuildItem,
            theme.colorDark
          )}
        >
          More than data.
        </p>
        <p
          className={cx(
            base.tc,
            main.stCopy,
            main.whyBuildItem,
            theme.colorDark
          )}
        >
          <strong>You deserve more than just data. </strong>A range of
          professional services is included within every client engagement that
          is essential to ongoing performance improvement. Our Customer Success
          Team is there every step of the way to ensure transparency, fluid
          orchestration, and measurable results.
        </p>
      </div>
    </section>
  );
};

export default Build;
